const tabs = [
  { name: '6M',  current: false },
  { name: '1Y', current: false },
  { name: 'YTD', current: false },
  { name: 'MAX', current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function RangeSelector( props: {selectedDate: string, setSelectedDate: (value: string) => void }) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-gray-500 focus:ring-gray-500"
          defaultValue={props.selectedDate}
          onChange={(e) => props.setSelectedDate(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              onClick={() => props.setSelectedDate(tab.name)}
              className={classNames(
                props.selectedDate === tab.name ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}
