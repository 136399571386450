import React, { createContext, useState, ReactNode } from "react";

type NotificationType = "success" | "error" | "warn";

interface Notification {
  id: number;
  title: string;
  message: string;
  type: NotificationType;
}

interface NotificationContextProps {
  notifications: Notification[];
  addNotification: (title: string, message: string, type?: NotificationType) => void;
  removeNotification: (id: number) => void;
}

const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
  addNotification: () => console.log("add"),
  removeNotification: () => console.log("remove"),
});

const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (title: string, message: string, type: NotificationType = "success") => {
    const id = Date.now();
    setNotifications((prevNotifications) => [...prevNotifications, { id, title, message, type }]);
  };

  const removeNotification = (id: number) => {
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
  };

  return <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>{children}</NotificationContext.Provider>;
};

export { NotificationContext, NotificationProvider };
