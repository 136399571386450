export default function SearchInput(props: {filter: string, setFilter: any}) {
  return (
    <div className="relative w-1/3 ml-1">
      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none z-20 ">
        <svg
          className="shrink-0 w-4 h-4 text-stone-500 dark:text-neutral-400 ml-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <path d="m21 21-4.3-4.3" />
        </svg>
      </div>
      <input
        type="text"
        className="py-[7px] pl-10 pr-8 block w-full z-10 bg-stone-100 border-transparent rounded-lg text-sm placeholder:text-stone-500 focus:border-blue-500 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
        placeholder="Search Participants"
        onChange={(e) => props.setFilter(e.target.value)}
      />
    </div>
  );
}
