import Papa from "papaparse";
import { useContext, useState } from "react";
import Button from "../../components/Button";
import UsersTable from "./UsersTable";
import useApi from "../../hooks/useApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import { ArrowUpOnSquareIcon, ExclamationTriangleIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ParticipantUpload, ParticipantWithGroups } from "../../types";
import { UserPlusIcon } from "@heroicons/react/16/solid";

export default function UserUpload(props: { onUpload?: () => void }) {
  const [uploadedUsers, setUploadedUsers] = useState<ParticipantUpload[]>([]);
  const [invalidRows, setInvalidRows] = useState<{ row: number; error: string }[]>([]);
  const [warningRows, setWarningRows] = useState<{ row: number; warning: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { postApiData } = useApi();
  const queryClient = useQueryClient();
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();

  const { data: participants } = useQuery<ParticipantWithGroups[]>({
    queryKey: ["participants", companyContext.companyId],
    queryFn: async () => getApiData(`/company/users/with-groups`),
    initialData: [],
  });

  async function uploadUsers() {
    try {
      setLoading(true);

      await postApiData("/company/users/bulk", uploadedUsers);

      queryClient.invalidateQueries({ queryKey: ["participants", companyContext.companyId] });
      queryClient.invalidateQueries({ queryKey: ["groups", companyContext.companyId] });

      setUploadedUsers([]);
      setLoading(false);
      if (props.onUpload) {
        props.onUpload();
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="py-5 sm:p-0">
      {uploadedUsers.length <= 0 && (
        <div className="pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
            <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center">
                <ArrowUpOnSquareIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-strataBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-strataBlue focus-within:ring-offset-2 hover:text-blue-900"
                  >
                    <span>Choose a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      accept=".csv,.xlsx,.xls"
                      onChange={(e) => {
                        let files = e.target.files;
                        if (files) {
                          Papa.parse(files[0], {
                            complete: function (results: any) {
                              let userArrays = results.data.slice(1);
                              userArrays = userArrays.map((array: any) => array.map((string: string) => string.trim())); // trim all whitespace
                              let validUsers: ParticipantUpload[] = [];
                              let invalidRowsTemp: {
                                row: number;
                                error: string;
                              }[] = [];
                              let warningRowsTemp: {
                                row: number;
                                warning: string;
                              }[] = [];

                              //remove users with either both blank email and phone number or blank job title
                              for (let i = 0; i < userArrays.length; i++) {
                                // Columns:
                                // 1: First Name
                                // 2: Last Name
                                // 3: Email
                                // 4: Phone Number
                                // 5: Job Title
                                // 6+: Groups
                                const FIRST_NAME_INDEX = 0;
                                const LAST_NAME_INDEX = 1;
                                const EMAIL_INDEX = 2;
                                const PHONE_NUMBER_INDEX = 3;
                                const JOB_TITLE_INDEX = 4;
                                const GROUP_INDEX = 5;

                                // skip if array is invalid ie. less than 3 elements
                                // excel puts empty new line at the end of csv files
                                if (userArrays[i].length < 2) {
                                  continue;
                                }

                                // strip non-digit characters from phone number
                                if (userArrays[i][PHONE_NUMBER_INDEX]) {
                                  userArrays[i][PHONE_NUMBER_INDEX] = userArrays[i][PHONE_NUMBER_INDEX].replace(/\D/g, "");
                                }

                                if (userArrays[i][PHONE_NUMBER_INDEX] && userArrays[i][PHONE_NUMBER_INDEX].length >= 20) {
                                  warningRowsTemp.push({
                                    row: i + 1,
                                    warning: "Phone number is invalid",
                                  });
                                  userArrays[i][PHONE_NUMBER_INDEX] = "";
                                }

                                if (!userArrays[i][EMAIL_INDEX] && !userArrays[i][PHONE_NUMBER_INDEX]) {
                                  invalidRowsTemp.push({
                                    row: i + 1,
                                    error: "Email or phone number must be provided",
                                  });
                                  continue;
                                }

                                validUsers.push({
                                  first_name: userArrays[i][FIRST_NAME_INDEX],
                                  last_name: userArrays[i][LAST_NAME_INDEX],
                                  email: userArrays[i][EMAIL_INDEX],
                                  phone_number: userArrays[i][PHONE_NUMBER_INDEX],
                                  job_title: userArrays[i][JOB_TITLE_INDEX],
                                  group_names: userArrays[i].slice(GROUP_INDEX), // gets all columns after index as different groups
                                });
                              }

                              if (validUsers.length > 0) {
                                setUploadedUsers(validUsers);
                                setInvalidRows(invalidRowsTemp);
                                setWarningRows(warningRowsTemp);
                              } else {
                                alert("CSV upload did not contain any valid users. Check the file to ensure data is in the correct format.");
                                e.target.value = "";
                              }
                            },
                          });
                        }
                      }}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">CSV</p>
              </div>
            </div>
          </dd>
        </div>
      )}

      {uploadedUsers.length > 0 && (
        <div>
          <UsersTable users={uploadedUsers} isUpload={true} limit={6} />
          {invalidRows.length > 0 && (
            <div className="rounded-md bg-yellow-50 p-4 mb-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">The following lines were not imported:</h3>
                </div>
              </div>
              <div className="mt-2 text-sm text-yellow-700 ml-1">
                <ul>
                  {invalidRows.map((row) => (
                    <li key={row.row}>
                      <span className="font-medium">Row {row.row}</span>: {row.error}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {warningRows.length > 0 && (
            <div className="rounded-md bg-yellow-50 p-4 mb-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">The following lines had issues but were still imported:</h3>
                </div>
              </div>
              <div className="mt-2 text-sm text-yellow-700 ml-1">
                <ul>
                  {warningRows.map((row) => (
                    <li key={row.row}>
                      <span className="font-medium">Row {row.row}</span>: {row.warning}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className="flex justify-between py-4 sm:py-5">
            <Button
              text="Clear"
              icon={XMarkIcon}
              variant="secondary"
              onClick={() => {
                setUploadedUsers([]);
                setInvalidRows([]);
                setWarningRows([]);
              }}
            />
            <Button text={participants.length > 0 ? "Update Participants" : "Import Participants"} icon={UserPlusIcon} submitting={loading} onClick={() => uploadUsers()} />
          </div>
        </div>
      )}
    </div>
  );
}
