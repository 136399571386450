import React from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";

import Nav from "./pages/Nav/Nav";
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from "./contexts/authContext";

import Home from "./pages/Home/Home";
import Account from "./pages/Settings/Account";
import Login from "./pages/Login/Login";
import Logout from "./pages/Login/Logout";
import Registration from "./pages/Login/Registration";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Payment from "./pages/Settings/ManagePayment";
import SchedulePage from "./pages/Settings/Schedule";
import ManageAdmins from "./pages/Settings/ManageAdmins";
import Mental from "./pages/Report/Report";
import Settings from "./pages/Settings/Settings";
import { CompanyProvider } from "./contexts/companyContext";
import ContactUs from "./pages/Support/ContactUs";
import { NotificationProvider } from "./contexts/notificationContext";
import NotificationsList from "./components/Notifications/NotificationList";
import MFA from "./pages/Login/MFA";
// import { Participants } from "./pages/Participants/Participants";
import Participants from "./pages/Participants/Users";
import Risk from "./pages/Risk/Risk";
import Compare from "./pages/Team/TeamOverview";
import Overview from "./pages/Overview/Overview";

export interface IContextProps {}

const App: React.FC<IContextProps> = (props) => {
  return (
    <div className="bg-background h-full">
      <Router>
        <NotificationProvider>
          <NotificationsList />
          <AuthProvider>
            {/* User is signed in */}
            <AuthIsSignedIn>
              <CompanyProvider>
                <Routes>
                  <Route path="" element={<Nav />}>
                    <Route path="home" element={<Home />} />
                    <Route path="accounts" element={<Overview />} />
                    <Route path="/*" element={<Navigate to={"/home"} />} />
                    <Route path="participants" element={<Participants />} />
                    <Route path="results" element={<Mental />} />
                    <Route path="risk" element={<Risk />} />
                    <Route path="team" element={<Compare />} />
                    <Route path="assessments/:id" element={<Mental />} />
                    <Route path="settings/payment" element={<Payment />} />
                    <Route path="settings/account" element={<Account />} />
                    <Route path="settings/schedule" element={<SchedulePage />} />
                    <Route path="settings/admins" element={<ManageAdmins />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="contact" element={<ContactUs />} />
                    <Route path="" element={<Navigate to={"home"} />} />
                  </Route>
                  <Route path="/login" element={<Navigate to={"/home"} />} />
                </Routes>
              </CompanyProvider>
            </AuthIsSignedIn>

            {/* User is not signed in */}
            <AuthIsNotSignedIn>
              <Routes>
                <Route path="/" element={<Navigate to={"/login"} />} />
                <Route path="/*" element={<Navigate to={"/login"} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/mfa" element={<MFA />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Routes>
            </AuthIsNotSignedIn>
          </AuthProvider>
        </NotificationProvider>
      </Router>
    </div>
  );
};

export default App;
