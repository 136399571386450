import React, { useContext } from "react";
import { NotificationContext } from "../../contexts/notificationContext";
import Notification from "./Notification";

const NotificationsList: React.FC = () => {
  const notificationContext = useContext(NotificationContext);

  return (
    <div className="notifications-list fixed top-10 right-10 w-96 z-50 space-y-4">
      {notificationContext.notifications.map((notification) => (
        <Notification key={notification.id} id={notification.id} title={notification.title} message={notification.message} type={notification.type} />
      ))}
    </div>
  );
};

export default NotificationsList;
