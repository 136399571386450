import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { getInitialsFromName } from "../../hooks/helpers";
import { Avatar } from "../Nav/Avatar";
import { CompanySurveySummary } from "../../types";
import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import { useNavigate } from "react-router";

export default function CompanyOverview(props: { companySurvey: CompanySurveySummary; viewOnly?: boolean }) {
  const { companySurvey, viewOnly } = props;

  const companyContext = useContext(CompanyContext);
  const navigate = useNavigate();

  return (
    <>
      <div className="overflow-hidden rounded-xl border border-gray-300">
        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-blue-50 p-6">
          <Avatar slot="icon" initials={getInitialsFromName(companySurvey.company_name)} className="bg-strataBlue text-white h-12 w-12 flex-none rounded-lg object-cover ring-1 ring-gray-900/10" />
          <div className="text-sm font-medium leading-6 text-gray-900">{companySurvey.company_name}</div>
          {!viewOnly && (
            <Menu as="div" className="relative ml-auto">
              <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5" />
              </MenuButton>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                  <div
                    onClick={() => {
                      companyContext.setCompanyId(companySurvey.company_id);
                      navigate("/home");
                    }}
                    className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                  >
                    View<span className="sr-only">, {companySurvey.company_name}</span>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div
                    onClick={() => {
                      companyContext.setCompanyId(companySurvey.company_id);
                      navigate("/settings");
                    }}
                    className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                  >
                    Settings<span className="sr-only">, {companySurvey.company_name}</span>
                  </div>
                </MenuItem>
              </MenuItems>
            </Menu>
          )}
        </div>
        <dl className="divide-y divide-gray-200 px-6 text-sm leading-6 bg-white">
          <div className="flex justify-between gap-x-4 py-4">
            <dt className="text-gray-500">SI Value</dt>
            <dd className="text-gray-700">
              {companySurvey.si_value ? (
                <div>
                  <span className={`${parseFloat(companySurvey.si_value) > 66.66 ? "text-green-500" : parseFloat(companySurvey.si_value) > 33.33 ? "text-yellow-500" : "text-red-500"}`}>
                    {parseFloat(companySurvey.si_value).toFixed(1)}
                  </span>{" "}
                  / 100
                </div>
              ) : (
                <div className="text-gray-400">No Results</div>
              )}
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-4">
            <dt className="text-gray-500">Response Distribution</dt>
            {companySurvey.si_value ? (
              <dd className="flex items-start gap-x-1">
                <div className="rounded-md text-xs font-medium ring-1 ring-red-600 ring-inset bg-red-500 text-white w-8 h-6 flex items-center justify-center">{companySurvey.red_count}</div>
                <div className="rounded-md text-xs font-medium ring-1 ring-yellow-600 ring-inset bg-yellow-500 text-white w-8 h-6 flex items-center justify-center">{companySurvey.yellow_count}</div>
                <div className="rounded-md text-xs font-medium ring-1 ring-green-600 ring-inset bg-green-500 text-white w-8 h-6 flex items-center justify-center">{companySurvey.green_count}</div>
                <div className="rounded-md text-xs font-medium ring-1 ring-gray-500 ring-inset bg-gray-400 text-white w-8 h-6 flex items-center justify-center">{companySurvey.disengaged_count}</div>
              </dd>
            ) : (
              <div className="text-gray-400">No Results</div>
            )}
          </div>
        </dl>
      </div>
    </>
  );
}
