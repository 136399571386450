import Button from "../../components/Button";
import { AuthContext } from "../../contexts/authContext";
import Spinner from "../../components/Spinner";
import { Company } from "../../types";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import Alert from "../../components/Alert";
import { CompanyContext } from "../../contexts/companyContext";
import { NotificationContext } from "../../contexts/notificationContext";
import { ErrorMessage, Field, Formik, Form } from "formik";
import Tooltip from "../../components/Tooltip";
import { useContext, useEffect, useState } from "react";
import MFASetupModal from "./MFASetupModal";
import { CheckCircleIcon, EllipsisVerticalIcon } from "@heroicons/react/16/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Divider from "../../components/Divider";

export default function Account() {
  const auth = useContext(AuthContext);
  const companyContext = useContext(CompanyContext);
  const { getApiData, postApiData } = useApi();
  const notificationContext = useContext(NotificationContext);
  const [showMFAModal, setShowMFAModal] = useState<boolean>(false);

  const {
    isPending: companyIsPending,
    isError: companyIsError,
    data: company,
  } = useQuery<Company>({
    queryKey: ["company", companyContext.companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  if (companyIsPending) {
    return <Spinner />;
  }

  if (companyIsError) {
    return <Alert />;
  }
  return (
    <div>
      <div className="px-4 sm:px-0 pb-5 border-b ">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Account Details</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">View and manage your account settings</p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {auth.claims?.given_name} {auth.claims?.family_name}
            </dd>
          </div>
          <Divider heading={""} />
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{auth.claims?.email}</dd>
          </div>
          <Divider heading={""} />
        </dl>
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-8 md:grid-cols-3">
        <div>
          <h3 className="text-sm font-medium leading-6 text-gray-900">Change password</h3>
          <p className="mt-1 text-sm leading-6 text-gray-400">Update your password associated with your account.</p>
          <div className="text-sm text-gray-400 mt-2">
            <div className="text-sm text-gray-500">Requirements:</div>
            <ul className="list-disc ml-4">
              <li>Contains at least 1 number</li>
              <li>
                <div className="flex">
                  Contains at least 1 special character{" "}
                  <span className="ml-1">
                    <Tooltip
                      message={`The following characters count as special characters: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ + =`}
                    />
                  </span>
                </div>
              </li>
              <li>Contains at least 1 uppercase letter</li>
              <li>Contains at least 1 lowercase letter</li>
            </ul>
          </div>
        </div>

        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validate={(values) => {
            const { currentPassword, newPassword, confirmPassword } = values;
            const errors: any = {};
            if (!currentPassword) {
              errors.currentPassword = "Current password is required";
            }
            if (!newPassword) {
              errors.newPassword = "New password is required";
            }
            if (!confirmPassword) {
              errors.confirmPassword = "Confirm password is required";
            }
            if (confirmPassword !== newPassword) {
              errors.confirmPassword = "Passwords do not match";
            }
            return errors;
          }}
          onSubmit={async (values, { setErrors }) => {
            const { currentPassword, newPassword } = values;
            const { response, body } = await postApiData("/company/admin/change-password", { currentPassword, newPassword });

            if (response.status === 201) {
              notificationContext.addNotification("Success!", "Password has been changed successfully");
              return;
            }

            if (body.type === "IncorrectPassword") {
              setErrors({ currentPassword: "Incorrect current password" });
              return;
            }
            if (body.type === "InvalidPassword") {
              setErrors({ newPassword: body.errors.join(", ") });
              return;
            }
            if (body.type === "LimitExceded") {
              notificationContext.addNotification("Error", body.errors.join(", "), "error");
              return;
            }

            notificationContext.addNotification("Error", "An unexpected error occurred, please try again later", "error");
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <label htmlFor="currentPassword" className="block text-sm font-medium leading-6 text-gray-900">
                    Current password
                  </label>
                  <div className="mt-2">
                    <Field
                      id="currentPassword"
                      name="currentPassword"
                      type="password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-strataBlue sm:text-sm sm:leading-6"
                    />
                  </div>
                  <ErrorMessage className="text-red-500 text-xs" name="currentPassword" component="div" />
                </div>

                <div className="col-span-full">
                  <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900">
                    New password
                  </label>
                  <div className="mt-2">
                    <Field
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-strataBlue sm:text-sm sm:leading-6"
                    />
                  </div>
                  <ErrorMessage className="text-red-500 text-xs" name="newPassword" component="div" />
                </div>

                <div className="col-span-full">
                  <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                    Confirm password
                  </label>
                  <div className="mt-2">
                    <Field
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-strataBlue sm:text-sm sm:leading-6"
                    />
                  </div>
                  <ErrorMessage className="text-red-500 text-xs" name="confirmPassword" component="div" />
                </div>
              </div>

              <div className="mt-4 flex">
                <Button text="Change Password" type="submit" submitting={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mt-8">
        <Divider heading={""} />
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-8 md:grid-cols-3 mt-7">
        <div>
          <h3 className="text-sm font-medium leading-6 text-gray-900">Multi-factor authentication</h3>
          <p className="mt-1 text-sm leading-6 text-gray-400">Choose to add multi-factor authentication to your account</p>
        </div>
        <div className="md:col-span-2 mb-24">
          {auth.mfaEnabled ? (
            <div>
              <div className="rounded-md bg-green-50 p-4 w-full ">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">Enabled</h3>
                    <div className="mt-2 text-sm text-green-700">
                      <p>Two-factor authentication is enabled for your account</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block text-sm font-medium leading-6 text-gray-900 mt-5">Remembered Devices</div>
              {auth.rememberedDevices.length > 0 ? (
                <ul className="divide-y divide-gray-100">
                  {auth.rememberedDevices.map((device, index) => (
                    <li key={index} className="flex items-center justify-between gap-x-6 bg-white rounded px-4 py-3">
                      <div className="min-w-0">
                        <div className="flex items-start gap-x-3">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {device.DeviceAttributes.find((d: any) => d.Name === "device_name").Value}
                          </p>
                          <p className="bg-green-100 text-green-700 mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                            {device.DeviceAttributes.find((d: any) => d.Name === "device_status").Value}
                          </p>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                          <p className="whitespace-nowrap">
                            Added:{" "}
                            <time dateTime={device.DeviceCreateDate}>
                              {new Date(device.DeviceCreateDate * 1000).toLocaleString()}
                            </time>
                          </p>
                          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                            <circle r={1} cx={1} cy={1} />
                          </svg>
                          <p className="truncate">
                            Last authenticated:{" "}
                            <time dateTime={device.DeviceLastAuthenticatedDate}>
                              {new Date(device.DeviceLastAuthenticatedDate * 1000).toLocaleString()}
                            </time>
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-none items-center gap-x-4">
                        <Menu as="div" className="relative flex-none">
                          <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                          </MenuButton>
                          <MenuItems
                            transition
                            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                          >
                            <MenuItem>
                              <div
                                onClick={() => {
                                  auth.forgetDevice && auth.forgetDevice(device.DeviceKey);
                                  window.location.reload();
                                }}
                                className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50 cursor-pointer"
                              >
                                Forget Device
                              </div>
                            </MenuItem>
                          </MenuItems>
                        </Menu>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="mt-1 text-sm leading-6 text-gray-400">No remembered devices</div>
              )}
            </div>
          ) : (
            <Button text="Enable MFA" onClick={() => setShowMFAModal(true)} />
          )}
        </div>
      </div>
      <MFASetupModal open={showMFAModal} setOpen={setShowMFAModal} />
    </div>
  );
}
