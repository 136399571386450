import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/authContext";
import Spinner from "../../components/Spinner";
import { Company, CompanySurveySummary, Schedule, Subscription, Survey } from "../../types";
import { useLocation, useNavigate } from "react-router";
import WelcomeModal from "./WelcomeModal";
import PreSurveySteps from "./PreSurveySteps";
import Container from "../../components/Container";
import Gauge from "../Report/SIValueGuage";
import TrendChart from "../../components/TrendChart";
import { useQuery } from "@tanstack/react-query";
import CountdownTimer from "../../components/Countdown";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import Alert from "../../components/Alert";
import HomeStats from "./HomeStats";
import { getLatestCompletedSurvey, getPreviousCompletedSurvey } from "../../hooks/helpers";
import SIValueFactors from "./SIValueFactors";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/16/solid";
import CompanyOverview from "./CompanyOverview";

export default function Home() {
  const auth = useContext(AuthContext);
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const location = useLocation();
  const companyId = companyContext.companyId;

  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);
  const [openFactors, setOpenFactors] = useState<boolean>(false);

  const { isPending: scheduleIsPending, data: schedule } = useQuery<Schedule | null>({
    queryKey: ["schedule"],
    queryFn: () => getApiData(`/company/schedule`),
  });

  const { isPending: paymentInfoIsPending, data: paymentInfo } = useQuery<Subscription>({
    queryKey: ["payment", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/info`),
    retry: false,
  });

  const {
    isPending: surveysIsPending,
    error: surveysError,
    data: surveys,
  } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: () => getApiData(`/company/surveys`),
    enabled: !!companyId,
  });


  useEffect(() => {
    const urlShowWelcome = new URLSearchParams(location.search).get("welcome");

    if (!scheduleIsPending && !paymentInfoIsPending) {
      if (!schedule || !paymentInfo || paymentInfo.stripe_subscription_status !== "active") {
        setTimeout(() => {
          if (typeof urlShowWelcome === "string") {
            setShowWelcomeModal(true);
          }
        }, 500);
      }
    }
  }, [location.search, paymentInfo, schedule, scheduleIsPending, paymentInfoIsPending]);

  const previousCompletedSurvey = surveys ? getPreviousCompletedSurvey(surveys) : null;
  const latestCompletedSurvey = surveys ? getLatestCompletedSurvey(surveys) : null;

  const nextSurvey = surveys?.find((s) => new Date(s.start_date) > new Date());

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyContext.companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  const { data: subsidiaries } = useQuery<Company[]>({
    queryKey: ["subsidiaries", companyId],
    queryFn: async () => getApiData(`/company/info/subsidiaries/all`),
    initialData: [],
  });

  const { data: subsidiaryCompanySurveys } = useQuery<CompanySurveySummary[]>({
    queryKey: ["subsidiaryCompanySurveys", companyId],
    queryFn: () => getApiData(`/company/surveys/get/latest-completed/subsidiaries`),
    enabled: !!companyId,
    initialData: [],
  });

  if (surveysError) {
    return <Alert title="Error" message="An unexpected error occurred, please try again later" type="Error" />;
  }
  if (surveysIsPending) {
    return <Spinner />;
  }

  return (
    <>
      <WelcomeModal open={showWelcomeModal} setOpen={setShowWelcomeModal} />
      <div className="min-h-full">
        <div className="flex justify-between items-center w-full">
          <div>
            <h1 className="text-2xl font-bold">Hi, {auth.claims.given_name}</h1>
            {company?.has_assessments && !latestCompletedSurvey ? (
              <h1 className="mt-2 mb-6">Complete these steps to set up your account</h1>
            ) : (
              <h1 className="mt-2 mb-6">Here is how your team is doing</h1>
            )}
          </div>
          {nextSurvey && (
            <div className="text-right">
              <h6 className="text-gray-600 text-sm mb-2">Next Assessment</h6>
              <CountdownTimer endDate={new Date(nextSurvey?.start_date)} />
            </div>
          )}
        </div>
        {company?.has_assessments && !latestCompletedSurvey && <PreSurveySteps />}

        {company?.has_assessments && latestCompletedSurvey && (
          <div className="mb-12">
            <Container title="" className="mb-4 border-2 border-blue-500">
              {latestCompletedSurvey && latestCompletedSurvey.si_value && (
                <div className="h-full sm:flex ">
                  <div className="flex-none sm:w-80 border-slate-400 mr-2 items-center justify-center rounded pb-8">
                    <p className="mx-auto lg:mt-0 font-medium text-lg text-blue-600">SI Value</p>
                    <Gauge siValue={parseFloat(latestCompletedSurvey.si_value)} lastSIValue={previousCompletedSurvey?.si_value ? parseFloat(previousCompletedSurvey.si_value) : undefined} />
                  </div>
                  <div className="flex-1">
                    <TrendChart
                      title="SI Value"
                      showDataLabel={true}
                      showTitle={false}
                      datasets={[
                        {
                          label: "SI Value",
                          data: surveys
                            .filter((s) => new Date(s.end_date) < new Date())
                            .map((s) => {
                              return {
                                value: s.si_value ? parseFloat(s.si_value) : null,
                                date: new Date(s.start_date),
                              };
                            }),
                          color: "#3b82f6",
                        },
                      ]}
                      suggestedMax={100}
                      suggestedMin={0}
                      stepSize={20}
                      hideLegend={true}
                      hideRangeSelector={true}
                      className="-ml-5 sm:ml-0"
                    />
                  </div>
                </div>
              )}
              <div className="flex items-center mt-4 lg:mt-0">
                <button onClick={() => setOpenFactors(!openFactors)} className="font-normal text-sm text-blue-600">
                  {!openFactors ? `View Factors` : `Close Factors`}{" "}
                </button>
                <div className="h-5 w-5">{!openFactors ? <ChevronDownIcon /> : <ChevronUpIcon />}</div>
              </div>

              {openFactors && <SIValueFactors />}
            </Container>

            <div className="mt-8">
              <HomeStats />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
