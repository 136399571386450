export default function Button(props: {
  text: string;
  size?: string;
  variant?: "success" | "secondary" | "warning" | "danger";
  type?: "button" | "submit" | "reset" | undefined;
  submitting?: boolean;
  disabled?: boolean;
  hoverMessage?: string;
  className?: string;
  id?: string;
  onClick?: () => void;
  icon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">>;
}) {
  let color = "";

  switch (props.variant) {
    case "success":
      color = "bg-green-400 hover:bg-green-500 text-white";
      break;
    case "secondary":
      color = "bg-gray-50 hover:bg-gray-50 text-gray-700 ring-gray-300 ring-1";
      break;
    case "warning":
      color = "bg-yellow-400 hover:bg-yellow-500 text-black";
      break;
    case "danger":
      color = "bg-red-400 hover:bg-red-500 text-white";
      break;
    default:
      color =
        "bg-strataBlue hover:bg-strataBlue-700 focus:outline-none text-white";
  }

  return (
    <>
      <button
        id={props.id}
        disabled={props.submitting || props.disabled}
        type={props.type ? props.type : "button"}
        className={`items-center ${
          props.size === "sm"
            ? "px-2 py-1 text-sm"
            : props.size === "lg"
            ? "px-7 py-3 text-lg"
            : "px-4 py-2 text-sm"
        } border border-transparent font-medium rounded no-underline shadow-sm ${color} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-strataBlue
        flex justify-center
        ${
          props.hoverMessage &&
          "group relative inline-block underline duration-300"
        }
        ${props.disabled  && "opacity-50"}
        ${props.submitting  && "opacity-70"}
        ${props.className}`}
        onClick={props.onClick}
      >
        <span
          className={`-translate-y-full after:top-[100%] absolute hidden group-hover:flex -left-[130px] right-3 -top-2 w-80 px-3 py-3
           rounded-lg text-center text-black text-sm after:content-[''] after:absolute after:left-1/2 
            after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-white
            z-10 shadow-lg ring-1 ring-black/5 backdrop-filter backdrop-blur-[2px] bg-white bg-opacity-90`}
        >
          {props.hoverMessage}
        </span>
        {props.icon && (
          <props.icon className="h-5 w-5 -ml-1 mr-1" />
        )}
        {props.text}{" "}
        {props.submitting && (
          <svg
            className="h-5 w-5 animate-spin text-white ml-2"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </button>
    </>
  );
}
