import Button from "../../components/Button";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/authContext";
import { useContext } from "react";
import Logo from "../../assets/Logo.png";
import Tooltip from "../../components/Tooltip";

export default function Registration() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className="h-screen bg-gray-50">
      <div className="min-h-full flex flex-col py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Strata Intel" />
          <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
            Welcome New User!
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">Please create your new account</p>
        </div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            all: "",
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.firstName) {
              errors.firstName = "First name is required";
            }
            if (!values.lastName) {
              errors.lastName = "Last name is required";
            }
            if (!values.password) {
              errors.password = "New password is required";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "Password confirmation is required";
            }
            if (values.password !== values.confirmPassword) {
              errors.all = "Passwords must match";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            try {
              await auth.completeNewPasswordRegistration(values.password, {
                given_name: values.firstName,
                family_name: values.lastName,
              });
              navigate("/home?welcome");
            } catch (err: any) {
              setErrors({ all: err.message });
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded sm:px-10">
                <Form className="space-y-6">
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="firstName">
                      First Name
                    </label>
                    <Field
                      type="text"
                      name="firstName"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      placeholder="First Name"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs"
                      name="firstName"
                      component="div"
                    />
                  </div>
                  <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="lastName">
                      Last Name
                    </label>
                    <Field
                      type="text"
                      name="lastName"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      placeholder="Last Name"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs"
                      name="lastName"
                      component="div"
                    />
                  </div>
                  <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                      New Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      placeholder="Password"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs"
                      name="password"
                      component="div"
                    />
                  </div>
                  <div className="mt-5">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="confirmPassword"
                    >
                      Confirm Password
                    </label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      placeholder="Confirm Password"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs"
                      name="confirmPassword"
                      component="div"
                    />
                  </div>
                  <div className="text-sm text-gray-600">
                    <div className="text-sm font-medium text-gray-700">Password requirements:</div>
                    <ul className="list-disc ml-4">
                      <li>Contains at least 1 number</li>
                      <li>
                        <div className="flex">
                          Contains at least 1 special character{" "}
                          <span className="ml-1">
                            <Tooltip
                              message={`The following characters count as special characters: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ + =`}
                            />
                          </span>
                        </div>
                      </li>
                      <li>Contains at least 1 uppercase letter</li>
                      <li>Contains at least 1 lowercase letter</li>
                    </ul>
                  </div>
                  <ErrorMessage className="text-red-500 text-sm mt-5" name="all" component="div" />
                  <div className="flex w-full items-baseline mt-7">
                    <Button
                      type="submit"
                      text="Register"
                      submitting={isSubmitting}
                      className="w-full"
                    />
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
