import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/16/solid";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import { CompanyHealth, Respondent, Survey } from "../../types";
import useApi from "../../hooks/useApi";
import {
  calculatePercentageChange,
  filterImpairedRespondents,
  getAddedParticipantCountByMonth,
  getLatestCompletedSurvey,
  getPreviousCompletedSurvey,
  getRemovedParticipantCountByMonth,
} from "../../hooks/helpers";

export default function HomeStats() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();

  const companyId = companyContext.companyId;

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: () => getApiData(`/company/surveys`),
    enabled: !!companyId,
    initialData: [],
  });

  const previousCompletedSurvey = surveys ? getPreviousCompletedSurvey(surveys) : null;
  const latestCompletedSurvey = surveys ? getLatestCompletedSurvey(surveys) : null;

  const { data: previousRespondents } = useQuery<Respondent[]>({
    queryKey: ["respondents", previousCompletedSurvey?.survey_id],
    queryFn: async () => getApiData(`/company/surveys/respondents/${previousCompletedSurvey?.survey_id}`),
    enabled: !!previousCompletedSurvey,
    initialData: [],
  });

  const { data: latestRespondents } = useQuery<Respondent[]>({
    queryKey: ["respondents", latestCompletedSurvey?.survey_id],
    queryFn: async () => getApiData(`/company/surveys/respondents/${latestCompletedSurvey?.survey_id}`),
    enabled: !!latestCompletedSurvey,
    initialData: [],
  });

  const { data: companyHealth } = useQuery<CompanyHealth[]>({
    queryKey: ["companyHealth", companyId],
    queryFn: async () => getApiData(`/company/users/company-health`),
    initialData: [],
  });

  if (!latestCompletedSurvey) {
    return <div></div>;
  }

  // Response Rate
  const responseRate = latestCompletedSurvey.response_rate && Number.isNaN(parseFloat(latestCompletedSurvey.response_rate))
    ? "--"
    : latestCompletedSurvey.response_rate ? `${parseFloat(latestCompletedSurvey.response_rate).toFixed(0)}%` : 0;
  let responseRateChange = previousCompletedSurvey?.response_rate && latestCompletedSurvey.response_rate
    ? calculatePercentageChange(parseFloat(previousCompletedSurvey.response_rate), parseFloat(latestCompletedSurvey.response_rate))
    : 0;

  // Impaired Counts
  const latestImpairedRespondents = filterImpairedRespondents(latestRespondents);
  const previousImpairedRespondents = filterImpairedRespondents(previousRespondents);

  // Personnel change counts
  const latestRemovedParticipants = getRemovedParticipantCountByMonth(companyHealth, new Date(latestCompletedSurvey.end_date).getMonth());
  const previousRemovedParticipants = previousCompletedSurvey
    ? getRemovedParticipantCountByMonth(companyHealth, new Date(previousCompletedSurvey.end_date).getMonth())
    : 0;
  const latestAddedParticipants = getAddedParticipantCountByMonth(companyHealth, new Date(latestCompletedSurvey.end_date).getMonth());
  const previousAddedParticipants = previousCompletedSurvey
    ? getAddedParticipantCountByMonth(companyHealth, new Date(previousCompletedSurvey.end_date).getMonth())
    : 0;

  const stats = [
    {
      name: "Response Rate",
      value: responseRate,
      change: responseRateChange,
      isPositiveChange: true,
    },
    {
      name: "Impaired Individuals",
      value: latestImpairedRespondents.length,
      change: previousCompletedSurvey ? calculatePercentageChange(previousImpairedRespondents.length, latestImpairedRespondents.length) : 0,
      isPositiveChange: false,
    },
    {
      name: "Removed Personnel",
      value: latestRemovedParticipants,
      change: calculatePercentageChange(previousRemovedParticipants, latestRemovedParticipants),
      isPositiveChange: false,
    },
    {
      name: "New Personnel",
      value: latestAddedParticipants,
      change: calculatePercentageChange(previousAddedParticipants, latestAddedParticipants),
      isPositiveChange: true,
    },
  ];

  return (
    <dl className="mx-auto grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => (
        <div key={stat.name} className="flex flex-wrap items-baseline justify-between  gap-x-4 gap-y-2 px-4 py-2 sm:px-6 xl:px-8">
          <dt className="text-md font-medium leading-6 text-black">{stat.name}</dt>

          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-black">{stat.value}</dd>

          {previousCompletedSurvey && (
            <div className="flex items-center">
              <div
                className={`${(stat.change > 0 && stat.isPositiveChange) || (stat.change < 0 && !stat.isPositiveChange) ? "bg-green-100 text-green-800" : (stat.change > 0 && !stat.isPositiveChange) || (stat.change < 0 && stat.isPositiveChange) ? "bg-red-100 text-red-800" : "bg-gray-200 text-gray-800"} inline-flex items-baseline rounded-lg px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}
              >
                {stat.change > 0 ? (
                  <ArrowUpIcon
                    aria-hidden="true"
                    className={`-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center ${stat.isPositiveChange ? "text-green-500" : "text-red-500"}`}
                  />
                ) : (stat.change > 0 && !stat.isPositiveChange) || (stat.change < 0 && stat.isPositiveChange) ? (
                  <ArrowDownIcon
                    aria-hidden="true"
                    className={`-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center ${!stat.isPositiveChange ? "text-green-500" : "text-red-500"}`}
                  />
                ) : (
                  <div></div>
                )}
                <span className="sr-only"> {stat.change && stat.change > 0 ? "Increased" : "Decreased"} by </span>
                {Number.isNaN(stat.change) ? "--" : stat.change !== 0 ? `${stat.change}%` : "No change"}
              </div>
              <p className="text-black ml-1">from last month</p>
            </div>
          )}
        </div>
      ))}
    </dl>
  );
}
