import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";
import { Group, Participant, ParticipantUpload, ParticipantWithGroups } from "../../types";
import { formatPhoneNumber } from "../../hooks/helpers";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import IconButton from "../../components/IconButton";
import UpdateParticipantModal from "./UpdateParticipantModal";
import ConfirmModal from "../../components/ConfirmationModal";
import { AuthContext } from "../../contexts/authContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import Button from "../../components/Button";
import { UserPlusIcon } from "@heroicons/react/16/solid";
import AssignParticipantGroupsModal from "./AssignParticipantGroupsModal";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { NotificationContext } from "../../contexts/notificationContext";
import SearchInput from "./SearchInput";

export default function TableWithPagination(props: {
  users: ParticipantWithGroups[] | ParticipantUpload[] | any; //TODO whyyyyy typescript
  isUpload: boolean;
  limit: number;
  setSuccessNotification?: (text: string) => void;
}) {
  const { users, isUpload, limit, setSuccessNotification } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentUsers, setCurrentUsers] = useState<ParticipantWithGroups[] | ParticipantUpload[] | any>();
  const [userToUpdate, setUserToUpdate] = useState<ParticipantWithGroups>();
  const [userToDelete, setUserToDelete] = useState<Participant>();
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [checkedParticipants, setCheckedParticipants] = useState<Participant[]>([]);
  const [filter, setFilter] = useState<string>("");

  const range = (start: number, end: number) => {
    //@ts-ignore
    return [...Array(end).keys()].map((el) => el + start);
  };

  const auth = useContext(AuthContext);
  const queryClient = useQueryClient();
  const companyContext = useContext(CompanyContext);
  const { getApiData, deleteApiData } = useApi();
  const notifications = useContext(NotificationContext);

  const { isPending: groupsIsPending, data: groups } = useQuery<Group[]>({
    queryKey: ["groups", companyContext.companyId],
    queryFn: async () => getApiData(`/company/groups/with-users`),
    initialData: [],
  });

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  const start = (currentPage - 1) * limit + 1;
  const end = currentPage * limit <= users.length ? currentPage * limit : users.length;
  const pagesCount = Math.ceil(users.length / limit);
  const pages = range(1, pagesCount);

  useEffect(() => {
    const searchTerm = filter.toLowerCase();

    //@ts-ignore
    const filteredUsers: (ParticipantWithGroups | ParticipantUpload)[] = users.filter((user: any) => {
      // Convert both the user data and search term to lowercase for case-insensitive comparison

      if (searchTerm === "") {
        return true; // If search term is empty, show all users
      }
      return (
        user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.company_job_title_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) // assuming your user object has a name and email property
      );
    });

    setCurrentUsers(filteredUsers.slice(start - 1, end));
  }, [currentPage, start, end, users, filter]);

  async function deleteUser() {
    if (!userToDelete) {
      return;
    }

    try {
      const { response, body } = await deleteApiData(`/company/users/${userToDelete.user_id}`);

      if (response.status === 204) {
        if (setSuccessNotification) {
          setSuccessNotification("Participant removed successfully");
          queryClient.invalidateQueries({ queryKey: ["participants", companyContext.companyId] });
          queryClient.invalidateQueries({ queryKey: ["groups", companyContext.companyId] });
          return;
        }
      }

      if (body.name === "TokenExpiredError") {
        window.location.reload();
      }

      notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
      console.error(body);
    } catch (err: any) {
      console.error(err);
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
    if (event.target.checked) {
      setCheckedParticipants([...checkedParticipants, value]);
    } else {
      setCheckedParticipants(checkedParticipants.filter((cp) => cp.user_id !== value.user_id));
    }
  };

  return (
    <>
      <UpdateParticipantModal
        participant={userToUpdate}
        open={openUserModal}
        setOpen={setOpenUserModal}
        setSuccessNotification={setSuccessNotification}
      />
      <ConfirmModal
        open={showDeleteConfirmation}
        setOpen={setShowDeleteConfirmation}
        title="Confirm"
        text="Are you sure you want to remove this participant?"
        onConfirm={deleteUser}
      />
      <AssignParticipantGroupsModal
        participants={checkedParticipants}
        setParticipants={setCheckedParticipants}
        open={openGroupModal}
        setOpen={setOpenGroupModal}
      />
      <div className="px-4 sm:px-6 lg:px-8 overflow-x-scroll sm:overflow-y-hidden">
        <div className="flex flex-col mt-4">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="">
                {!isUpload && users.length < 5 && (
                  <div className="rounded-md bg-yellow-50 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                      </div>
                      <div className="ml-3">
                        <div className="text-sm text-yellow-700">
                          To view the results for this group, please ensure there are at least 5 participants to maintain anonymity
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isUpload && (
                  <div className="flex justify-between mb-4">
                    <SearchInput filter={filter} setFilter={setFilter} />

                    {checkedParticipants.length > 0 && (
                      <div className="flex">
                        <div className="mr-3 text-sm text-gray-700 my-auto">{checkedParticipants.length} selected</div>
                        <Button text="Add to Group" variant="warning" icon={UserPlusIcon} onClick={() => setOpenGroupModal(true)} />
                      </div>
                    )}
                  </div>
                )}
                <div className="border rounded-md">
                  <table className="min-w-full shadow-sm" style={{ borderSpacing: 0 }}>
                    <thead className="stone-2bg-00">
                      <tr>
                        {/* //checkbox */}
                        {!isUpload && (
                          <th
                            scope="col"
                            className="rounded-md sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter w-10"
                          >
                            <input
                              id="comments"
                              name="comments"
                              type="checkbox"
                              checked={users.length === checkedParticipants.length}
                              onChange={() =>
                                // @ts-ignore
                                users.length === checkedParticipants.length ? setCheckedParticipants([]) : setCheckedParticipants(users)
                              }
                              aria-describedby="comments-description"
                              className="ml-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </th>
                        )}
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          Phone Number
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          Job Title
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          Groups
                        </th>
                        {!isUpload && (
                          <>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 backdrop-blur backdrop-filter"
                            ></th>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 backdrop-blur backdrop-filter"
                            ></th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {currentUsers?.map((user: any, index: number) => (
                        <tr key={index}>
                          {!isUpload && (
                            <td className={classNames(index !== users.length - 1 ? "border-b border-gray-200" : "", "")}>
                              <input
                                id="comments"
                                name="comments"
                                type="checkbox"
                                checked={checkedParticipants.find((cp) => cp.user_id === user.user_id) ? true : false}
                                onChange={(e) => handleCheckboxChange(e, user)}
                                aria-describedby="comments-description"
                                className="ml-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </td>
                          )}
                          <td
                            className={classNames(
                              index !== users.length - 1 ? "border-b border-gray-200" : "",
                              "whitespace-nowrap py-3 pl-4 text-sm text-gray-900"
                            )}
                          >
                            {user.first_name} {user.last_name}
                          </td>
                          <td
                            className={classNames(
                              index !== users.length - 1 ? "border-b border-gray-200" : "",
                              "whitespace-nowrap py-3 pl-4 text-sm text-gray-900"
                            )}
                          >
                            {user.email}
                          </td>
                          <td
                            className={classNames(
                              index !== users.length - 1 ? "border-b border-gray-200" : "",
                              "whitespace-nowrap py-3 pl-4 text-sm text-gray-900"
                            )}
                          >
                            {formatPhoneNumber(user.phone_number)}
                          </td>
                          <td
                            className={classNames(
                              index !== users.length - 1 ? "border-b border-gray-200" : "",
                              "whitespace-nowrap py-3 pl-4 text-sm text-gray-500"
                            )}
                          >
                            {isUpload ? user.job_title : user.company_job_title_name}
                          </td>
                          <td
                            className={classNames(
                              index !== users.length - 1 ? "border-b border-gray-200" : "",
                              "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                            )}
                          >
                            {isUpload
                              ? user.group_names.join(", ")
                              : user.group_ids &&
                                user.group_ids
                                  .map((id: number) => groups.find((group) => group.group_id === id))
                                  .map((group: Group) => group && group.group_name)
                                  .join(", ")}
                          </td>
                          {!isUpload && (
                            <>
                              <td
                                className={classNames(index !== users.length - 1 ? "border-b border-gray-200" : "", "py-3 text-sm text-gray-500 w-3")}
                              >
                                <IconButton
                                  icon={PencilIcon}
                                  onClick={() => {
                                    setUserToUpdate(user);
                                    setOpenUserModal(true);
                                  }}
                                  className="h-5"
                                />
                              </td>
                              <td
                                className={classNames(
                                  index !== users.length - 1 ? "border-b border-gray-200" : "",
                                  "py-3 text-sm text-red-400 flex justify-center"
                                )}
                              >
                                <IconButton
                                  icon={TrashIcon}
                                  onClick={() => {
                                    setUserToDelete(user);
                                    setShowDeleteConfirmation(true);
                                  }}
                                  className="h-5"
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between py-3">
        <div className="flex flex-1 justify-between sm:hidden">
          {currentPage !== 1 && (
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-white"
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          {currentPage !== pages.length && (
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-white"
            >
              Next
            </button>
          )}
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of{" "}
              <span className="font-medium">{users.length}</span> users
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`${
                  currentPage === 1 && "opacity-30"
                } relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20`}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-white" */}
              {pages.map((page) => (
                <div>
                  {page <= 13 && (
                    <button
                      key={page}
                      aria-current="page"
                      onClick={() => setCurrentPage(page)}
                      className={
                        page === currentPage
                          ? "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                          : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20"
                      }
                      disabled={page === currentPage}
                    >
                      {page}

                      {page === 13 && ` ...`}
                    </button>
                  )}
                </div>
              ))}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className={`${
                  currentPage === pages.length && "opacity-30"
                } relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20`}
                disabled={currentPage === pages.length}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
