import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/authContext";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import Button from "../../components/Button";

export default function Login() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const queryString = window.location.search;

  const searchParams: any = {};
  queryString
    .slice(1)
    .split("&")
    .forEach((param) => {
      const [key, value] = param.split("=");
      if (value) {
        searchParams[key] = value.replace(/\+/g, " ");
      }
    });

  const urlEmail = searchParams["email"];
  const urlPassword = searchParams["password"];

  useEffect(() => {
    if (urlEmail && urlPassword) {
      // Auto-submit the form when component mounts
      const submitButton = document.getElementById("submitButton") as HTMLFormElement | null;

      if (submitButton) {
        submitButton.click();
      }
    }
  }, [urlEmail, urlPassword]);

  return (
    <div className="h-screen bg-gray-50">
      <div className="min-h-full flex flex-col py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Strata Intel" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Welcome!</h2>
          <p className="mt-2 text-center text-sm text-gray-600">Sign into your account</p>
        </div>

        <Formik
          initialValues={{
            email: urlEmail ? urlEmail.replace(/ /g, "+") : "", //fix for when email contains "+"
            password: urlPassword ? urlPassword : "",
            all: "",
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Password is required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            try {
              await auth.signInWithEmail(values.email, values.password);
              navigate("/admin");
            } catch (err: any) {
              if (err.code === "newPasswordRequired") {
                navigate("/registration");
              } else if (err.code === "totpRequired") {
                navigate("/mfa");
              } else {
                setErrors({ all: err.message });
              }
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded sm:px-10">
                <Form className="space-y-6" id="loginForm">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      />
                    </div>
                    <ErrorMessage name="email" className="text-red-500 text-sm" component="div" />
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      />
                    </div>
                    <ErrorMessage name="password" className="text-red-500 text-sm" component="div" />
                  </div>

                  <ErrorMessage name="all" className="text-red-500 text-sm" component="div" />

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link to="/forgot-password" className="font-medium text-strataBlue hover:text-strataBlue">
                        Forgot your password?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <Button id="submitButton" type="submit" text="Sign in" submitting={isSubmitting} className="w-full" />
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
