import { useQuery } from "@tanstack/react-query";
import { Company, CompanySurveySummary, Survey } from "../../types";
import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { Avatar } from "../Nav/Avatar";
import { getInitialsFromName, getLatestCompletedSurvey } from "../../hooks/helpers";
import CompanyOverview from "../Home/CompanyOverview";

export default function TeamOverview() {
  const { companyId } = useContext(CompanyContext);
  const { getApiData } = useApi();

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: async () => getApiData(`/company/surveys`),
    initialData: [],
  });

  const { data: companySurveys } = useQuery<CompanySurveySummary[]>({
    queryKey: ["companySurveys", companyId],
    queryFn: () => getApiData(`/company/surveys/get/latest-completed/siblings`),
    enabled: !!companyId,
    initialData: [],
  });

  const latestCompletedSurvey = getLatestCompletedSurvey(surveys);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Team Overview</h1>
      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
        {companySurveys.map((companySurvey, index) => (
          <CompanyOverview companySurvey={companySurvey} viewOnly={true} />
        ))}
      </ul>
    </div>
  );
}
