import { useContext, useState } from "react";
import { ClipboardDocumentIcon, UserGroupIcon, CreditCardIcon, BellAlertIcon, UserIcon, IdentificationIcon } from "@heroicons/react/24/outline";

import Schedule from "./Schedule";
import ManageAdmins from "./ManageAdmins";
import ManagePayment from "./ManagePayment";
import Account from "./Account";
import Notifications from "./Notifications";
import Participants from "./Participants";
import { useQuery } from "@tanstack/react-query";
import { Company } from "../../types";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import Spinner from "../../components/Spinner";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Settings() {
  const [selectedTab, setSelectedTab] = useState<string>("Account");

  const { getApiData } = useApi();
  const { companyId, showAllAccounts } = useContext(CompanyContext);

  const {
    data: company
  } = useQuery<Company>({
    queryKey: ["company", companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  if(!company) {
    return <Spinner />
  }

  const tabs = [
    { name: "Account", href: "/settings/account", icon: UserIcon, show: true },
    { name: "Assessments", href: "/settings/schedule", icon: ClipboardDocumentIcon, show: company.has_assessments && !showAllAccounts },
    { name: "Admins", href: "/settings/admins", icon: UserGroupIcon, show: !showAllAccounts },
    { name: "Subscription", href: "/settings/payment", icon: CreditCardIcon, show: company.is_billing && !showAllAccounts },
    { name: "Participants", href: "/settings/participants", icon: IdentificationIcon, show: company.has_assessments && !showAllAccounts},
  ];

  return (
    <>
      <div className="mx-auto max-w-full lg:flex lg:gap-x-8 lg:px-2">
        <h1 className="sr-only">General Settings</h1>

        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-2">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {tabs.filter(t => t.show).map((item) => (
                <li key={item.name}>
                  <div
                    onClick={() => setSelectedTab(item.name)}
                    className={classNames(
                      item.name === selectedTab ? "bg-gray-50 text-strataBlue" : "text-gray-700 hover:bg-gray-50 hover:text-strataBlue",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 cursor-pointer"
                    )}
                  >
                    <item.icon
                      aria-hidden="true"
                      className={classNames(
                        item.name === selectedTab ? "text-strataBlue" : "text-gray-400 group-hover:text-strataBlue",
                        "h-6 w-6 shrink-0"
                      )}
                    />
                    {item.name}
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-2 sm:px-2 lg:flex-auto lg:px-0 lg:py-2">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            {/* Account Settings */}
            {selectedTab === "Account" && <Account />}

            {/* Assessment Settings */}
            {selectedTab === "Assessments" && <Schedule />}

            {/* Admin Settings */}
            {selectedTab === "Admins" && <ManageAdmins />}

            {/* Subscription Settings */}
            {selectedTab === "Subscription" && <ManagePayment />}

            {/* Notification Settings */}
            {selectedTab === "Notifications" && <Notifications />}

            {/* Participant Settings */}
            {selectedTab === "Participants" && <Participants />}
          </div>
        </main>
      </div>
    </>
  );
}
